body {
  color: white;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  background-color: #2F3A44;
}

a {
  color: white;
  text-decoration: none;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

a:hover {
  background-color: #495863;
  border-radius: 2px;
  color: #D2DBE0;
}

h1 {
  float: left;
}

p {
  color: white; font-family: 'DM Sans', Helvetica, Arial, sans-serif;
}

input {
  color: #495863;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
  height: 20px;
  width: 400px;
  padding: 2%;
  margin-right: 2%;
  border-radius: 2px;
}

h2{
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  color: white;
  font-family: 'DM Sans', Helvetica, Arial, sans-serif;
}

input[type=text] {
  border: 1px solid #ccc;
  outline: none;
}

footer {
  width: 97%;
  position: absolute;
  bottom: 0;
  text-align: right;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #495863;
}

@media only screen and (max-width: 800px) {
  footer {
    position: inherit;
    margin-top: 5em;
  }

  input {
    height: 20px;
    width: 165px;
    font-size: 18px;
    padding: 2%;
    margin-right: 2%;
    height: 27px;
  }
}