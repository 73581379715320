.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* --- */

.logo-country {
  background-size: 40px !important;
  background-color: #ffffff !important;
  background-position: 100% 150% !important;
}
nav {
  padding-top: 30px;
  float: right;
}
.nav-item {
  padding: 10px;
}

.block {
  padding: 1% 0px 8% 0px;
  display: flex;
}

.hidden {
  display: none;
}

.nav-item-selected {
  padding: 10px;
  background-color: #495863;
  border-radius: 2px;
}

.Menu {
  display: block;
  margin: 0px 1.2em;
}

.content {
  width: 100%;
  display: grid;
  padding-top: 8em;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #495863;
}

.main {
  width: 80%;
  margin: 0 auto;
}

.main-2 {
  width: 80%;
  margin: 0 auto;
  display: inline-grid;
}

.left {
  float: left;
}

.right {
  float: right;
  padding-right: 5%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.btn {
  color: #ffffff;
  background-color: #e6210a;
  padding: 12px;
  border-radius: 2px;
}
.btn:hover {
  color: #ffffff;
  background-color: #d53b2a;
}
.bi {
  display: inline-block;
  vertical-align: -0.3em;
  fill: currentcolor;
}
.btn-2 {
  color: #2f3a44;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 2px;
}
.btn-2:hover {
  color: #495863;
  background-color: #e7edef;
}

#input-amount-small {
  display: none;
}

#input-amount-large {
  display: inline-block;
}

.left-sec {
  width: 45%;
  float: left;
}

.right-sec {
  float: right;
  padding-right: 5%;
}

@media only screen and (max-width: 800px) {
  .left {
    float: left;
    height: 600px;
  }

  .left-sec {
    width: auto;
    float: left;
    height: 600px;
  }

  .right {
    display: none;
  }

  .right-sec {
    display: none;
  }

  .main {
    width: 90%;
    margin: 0 auto;
  }

  nav {
    padding-top: 30px;
    float: right;
  }

  footer {
    position: inherit;
    margin-top: 5em;
  }

  .block {
    display: flex;
  }

  .content {
    padding-top: 4em;
  }

  #input-amount-large {
    display: none;
  }

  #input-amount-small {
    display: inline-block;
  }

  .logo-country {
    background-position: 100% 100%;
    padding-top: 3%;
  }
}
